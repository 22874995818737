import moment from 'moment';
import 'moment/locale/de';

import * as terrific from './terrifc/terrific.js';
import * as Handlebars from "../../node_modules/handlebars/dist/handlebars.min.js";
import Cookies from 'js-cookie';
import Swiper from "swiper/bundle";
import '../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js';
import '../../node_modules/jquery-number/jquery.number.js';
import '../../node_modules/devbridge-autocomplete/dist/jquery.autocomplete.min.js';
import './../../libs/jquery-ui-sortable/jquery-ui-sortable.js';
import './../../libs/jquery-autocomplete/jquery.auto-complete.min.js';
import './../../libs/jquery-ui-datepicker/datepicker.js';
import '../../node_modules/jquery.maskedinput/src/jquery.maskedinput.js';
import "../../node_modules/jquery-validation/dist/jquery.validate.js";
import "../../node_modules/jquery-validation/dist/additional-methods.js";

window.S = {};
window.T = {};
window.jQuery = window.$ = jQuery;
window.moment = moment;
window.T = terrific;
window.Handlebars = Handlebars;
window.Cookies = Cookies;
window.Swiper = Swiper;
